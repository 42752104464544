import { Drawer } from "@mantine/core";

import SearchInput from "../searchInput";

import useStyles from "./styles";

const component = ( { toggleSearchModal, showSearchModal } ) => {
	const { classes } = useStyles ( undefined, { name: "styles" } );

	return (
		<Drawer
			classNames = { { body: classes.drawerBody, root: classes.drawerRoot } }
			onClose = { toggleSearchModal }
			opened  = { showSearchModal }
			position = "bottom"
			size = "100%"
			withCloseButton = { false }
		>
			<div className = { classes.searchModal }>
				<SearchInput onClose = { toggleSearchModal } />
			</div>
		</Drawer>

	);
};

export default component;