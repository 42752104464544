import { Popover, RemoveScroll } from "@mantine/core";
import { useRef }                from "react";

import { useGetDeviceType } from "_common/hooks/global";
import Icon                 from "_components/atoms/icon";
import SearchTextField      from "_components/molecules/searchTextField";
import SearchPopOver        from "_components/organisms/searchPopOver";

import useStyles from "./styles";

const component = ( {
	router,
	setSearchTerm,
	searchTerm,
	onClose,
	openPopover,
	closePopover,
	popoverOpened
} ) => {

	const { classes, cx } = useStyles ( { searchTerm } );
	const deviceType      = useGetDeviceType ();

	const isMobileOrTablet = deviceType === "mobile" || deviceType === "tablet";
	const inputRef         = useRef ( null );

	const isValidSearchString = str => {
		const noSpace = str.replace ( /\s/g, "" );

		if ( !noSpace )
			return false;

		return true;
	};

	const onPressEnter = () => {
		if ( searchTerm.length === 0 || !isValidSearchString ( searchTerm ) )
			return;

		closePopover ();
		router.push ( `/results/${ encodeURIComponent ( searchTerm ) }?tab=all` );
	};

	const onFocus = () => {
		openPopover ();
		inputRef.current?.focus ();
	};

	if ( isMobileOrTablet ) {
		return (
			<div className = { classes.mobileSearchModal }>
				<div className = { classes.mobileSearchContainer }>
					<Icon
						className = { classes.backIcon }
						name = "backArrow"
						onClick = { onClose }
					/>

					<SearchTextField
						inputRef = { inputRef }
						onFocus = { onFocus }
						onPressEnter = { onPressEnter }
						onPressEsc = { closePopover }
						placeholder = { popoverOpened ? "Search for Streamers, Videos, Games & More" : "Search" }
					/>

				</div>

				<SearchPopOver
					closePopover = { closePopover }
					popoverOpened = { popoverOpened }
					searchTerm = { searchTerm }
					setSearchTerm = { setSearchTerm }
				/>

			</div>
		);
	}

	return (
		<div className = { classes.headerChild }>
			<RemoveScroll enabled = { popoverOpened }>
				<div style = { { width: "min( 50vw, 460px )", padding: "0 30px" } }>
					<Popover
						autoFocus           = { true }
						classNames = { {
							dropdown: classes.popoverDropdown
						} }
						keepMounted = { true }
						onClick             = { onFocus }
						onClose             = { closePopover }
						opened              = { popoverOpened }
						position            = "bottom"
						transition          = "scale"
						width               = "38%"
						zIndex              = { 1115 }
					>
						<Popover.Target>
							<div>
								<SearchTextField
									inputRef     = { inputRef }
									onFocus      = { onFocus }
									onPressEnter = { onPressEnter }
									onPressEsc   = { closePopover }
									placeholder  = { popoverOpened ? "Search for Streamers, Videos, Games & More" : "Search" }
								/>
							</div>
						</Popover.Target>

						<Popover.Dropdown
							className = { cx ( classes.popOver, "hideScrollbar" ) }
						>
							<SearchPopOver
								closePopover  = { closePopover }
								popoverOpened = { popoverOpened }
								searchTerm    = { searchTerm }
								setSearchTerm = { setSearchTerm }
							/>
						</Popover.Dropdown>
					</Popover>
				</div>
			</RemoveScroll>
		</div>
	);
};

export default component;
